import http from "./httpService";
import {apiUrl} from '../config.json';

const apiEndpoint = apiUrl + "/infoPages";

function infoPageUrl(pageType) {
  return `${apiEndpoint}/${pageType}`;
}

export function getInfoPage(pageType) {
  return http.get(infoPageUrl(pageType));
}
