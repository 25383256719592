import React from 'react';
import storyIcon from '../../assets/img/storyIcon.png';
import {withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom'

// Css styling
const styles = theme => ({
    iconImg: {
        position: 'absolute',
        width: '30px',
        height: 'auto',
        // Offset story icons so that they are positioned by the tip rather than by the top left.
        // Make sure to adjust these if changing the icon size.
        left: '-15px',
        top: '-37px',
        zIndex: 9999
    }
});

class MapIcon extends React.Component {
    render() {
        const {classes, info} = this.props;
        return (
            <div>
                <div className={classes.container}>
                    <Link to={{pathname: '/story', state: {stories: [info]}}}>
                        <img alt='icon' className={classes.iconImg} src={storyIcon}/>
                    </Link>
                </div>
            </div>
        );
    }

}

export default withStyles(styles)(MapIcon);