import React from 'react';
import storyIconBlank from '../../assets/img/storyIconBlank.png';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {Link} from 'react-router-dom'

// Css styling
const styles = theme => ({
    container: {
        color: '#FFFFFF',
        textAlign: 'center'
    },
    iconImg: {
        position: 'relative',
        width: '30px',
        height: 'auto',
        // Offset story icons so that they are positioned by the tip rather than by the top left.
        // Make sure to adjust these if changing the icon size.
        left: '-15px',
        top: '-37px',
        zIndex: 9998
    },
    iconNumber: {
        position: 'relative',
        fontSize: 20,
        fontWeight: 'bold',
        color: 'red',
        left: '-15px',
        top: '-70px',
        padding: 0,
        margin: 0,
        zIndex: 9999
    }
});

class MapIconMerged extends React.Component {
    render() {
        const {classes, info} = this.props;
        return (
            <div className={classes.container}>
                <Link to={{pathname: '/story', state: {stories: info}}}>
                    <img className={classes.iconImg} src={storyIconBlank}/>
                    <p className={classes.iconNumber}>{info.length}</p>
                </Link>
            </div>
        );
    }

}

export default withStyles(styles)(MapIconMerged);