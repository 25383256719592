import React from 'react';

import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    sections: {
        padding: '0 0 0 10px'
    },
    container: {

    },
    body: {
        textDecoration: 'none',
        color: 'black'
    }
});

class PageTitle extends React.Component {

    render() {
        const {classes, text} = this.props;
        return (
            <div className={classes.sections}>
                <div className={classes.container}>
                    <div className={classes.body}>
                        <p>{text}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(PageTitle);