import React from 'react';

import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
    sections: {
        padding: '70px 0 0 10px'
    },
    container: {

    },
    title: {
        marginTop: '30px',
        minHeight: '32px',
        textDecoration: 'none',
        color: 'black'
    }
});

class PageTitle extends React.Component {

    render() {
        const {classes, text} = this.props;
        return (
            <div className={classes.sections}>
                <div className={classes.container}>
                    <div className={classes.title}>
                        <h2>{text}</h2>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(PageTitle);