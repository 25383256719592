import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Header from '../../components/Header/Header.js';
import Button from '@material-ui/core/Button';

const styles = theme => ({
    container: {
        textAlign: 'left',
        backgroundColor : '#68246D',
        height: '99vh'
    },
    card: {
        background: 'white',
        margin: '5px',
        height: '95vh',
        overflowY: 'scroll'
    },
    title: {
        minHeight: '32px',
        paddingTop: '0px',
        textDecoration: 'none',
        color: 'black'
    },
    bodyText: {
        padding: '5px',
        whiteSpace: 'pre-line'
    },
    storyPhoto: {
        width: '100%',
        padding: '5px'
    },
    video: {
        width: '99%',
        padding: '5px'
    },
	audio: {
		textAlign: 'center',
		padding: '5px'
	},
    storyNav: {
        textAlign: 'center',
        paddingTop: '65px',
        color: 'black'
    }
});

class Stories extends React.Component {
    state = {
        currentStory: 0
    };

    render() {
        const {classes} = this.props;
        const {stories} = this.props.location.state;
        const story = stories[this.state.currentStory];

        if (story.video === null || story.video === undefined) {
            delete story.video;
        }
        if (story.audio === null || story.audio === undefined) {
            delete story.audio;
        }
        if (story.image === null || story.image === undefined) {
            delete story.image;
        }

        return (
            <div>
                <Header fixed/>
                <div className={classes.container}>
                    <div className={classes.card}>
                        <div className={classes.storyNav}>{
                            stories.length > 1 ?
                                <div>
                                    Navigate other stories in this location.<br/>
                                    {this.state.currentStory > 0 ? <Button onClick={() => {
                                        this.setState({currentStory: this.state.currentStory - 1});
                                    }}>Previous</Button> : ""}
                                    {this.state.currentStory + 1} / {stories.length}
                                    {this.state.currentStory < stories.length - 1 ? <Button onClick={() => {
                                        this.setState({currentStory: this.state.currentStory + 1});
                                    }}>Next</Button> : ""}
                                </div>
                            : ""
                        }</div>
                        <div className={classes.bodyText}>
                            <h2>{story.title}</h2>
                            <h4>Author: {story.author}</h4>
                        </div>
                        { story.hasOwnProperty('video') ?
                        <div className={classes.video}>
                            <iframe title="video" width="100%" height="200vh" src={story.video} frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                        </div>
                        :(
                            story.hasOwnProperty('image') ? <img className={classes.storyPhoto} src={story.image} alt={'story'}/>
                            :(
                                story.hasOwnProperty('audio') ?
                                <div className={classes.audio}>
                                    <audio controls>
                                        <source src={story.audio} type={'audio/mp3'}/>
                                        {'Sorry, your browser does not support the audio tag. Please upgrade to a browser with HTML5.'}
                                    </audio>
                                </div>
                                : ""
                            )
                        )
                        }
                        <div className={classes.text}>
                            {story.text.join('\n')}
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default withStyles(styles)(Stories);
