import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.8.0";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// pages
import MapPage from "views/MapPage/MapPage.js";
import VolunteerPage from "views/VolunteerPage/VolunteerPage.js";
import DonationPage from './views/DonationPage/DonationPage';
import Stories from './views/Stories/Stories';

let hist = createBrowserHistory();

toast.configure();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      {/* Load component depending on URL path */}
      <Route path="/volunteer" component={VolunteerPage} />
      <Route path="/donate" component={DonationPage} />
      <Route path="/story" component={Stories} />
      <Route path="/" component={MapPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
