import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {getStories} from '../../services/storyService';
import Header from '../../components/Header/Header.js';
import MapSection from './Sections/MapSection.js';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

// Css styling
const styles = theme => ({
    container: {
        textAlign: 'left',
        backgroundColor: '#68246D',
        height: '99vh'
    },
    card: {
        background: '#68246D',
        height: '95vh',
        overflowY: 'scroll',
        margin: '5px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loadingIcon: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute',
        left: '50%',
        top: '50%',
    }
});

class MapPage extends React.Component {
    state = {
        stories: [],
        loading: false
    };

    componentDidMount() {
        this.setState({loading: true});
        getStories().then((response) => {
            this.setState({
                stories: response.data
            });
            this.setState({loading: false})
        });
    }

    render() {
        const {classes} = this.props;
        const {stories, loading} = this.state;
        return (
            <div>
                {/* Navbar section */}
                <Header fixed mainPage='true'/>
                <div className={classes.container}>
                    <div className={classes.card}>
                        <Backdrop className={classes.backdrop} open={this.state.loading}>
                        </Backdrop>
                        {loading && <CircularProgress className={classes.loadingIcon}/>}
                        {/* Load the map section HTML which is returned from the component "MapSection" */}
                        <MapSection stories={stories}/>
                    </div>
                </div>
            </div>
        );
    }

}

export default withStyles(styles)(MapPage);