import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import styles from "../../assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {/* Link to route will load a certain component */}
        <Link to="/volunteer">
          <button
            href="#"
            color="black"
            target="_blank"
            className={classes.navLink}
          >
            {" "}
            Volunteer
          </button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/">
          <button
            href="#"
            color="black"
            target="_blank"
            className={classes.navLink}
          >
            {" "}
            Donate
          </button>
        </Link>
      </ListItem>
    </List>
  );
}
