import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import MapIcon from '../../../components/MapIcon/MapIcon';
import MapIconMerged from '../../../components/MapIcon/MapIconMerged';

let bg = require('../../../assets/img/map.png');

// Css styling
const styles = theme => ({
    title: {
        color: 'black'
    },
    sections: {
        padding: '0 0'
    },
    centreFit: {
        maxWidth: '100%',
        maxHeight: '100vh',
        margin: '0'
    },
    container: {
        position: 'relative',
        margin: '56px 0px 0px 0px',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)'
    },
    map: {
        width: '100%'
    }
});

const generateStyle = story => {

    const top = story.yCoordinate + '%';
    const left = story.xCoordinate + '%';
    return {
        position: 'absolute',
        top: top,
        left: left,
        zIndex: 0
    }
};

class MapSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {width: 0};
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({width: window.innerWidth});
    }

    // The return function below is what is returned when loaded by MapPage.js
    render() {
        const {classes, stories} = this.props;

        let groupedStories = [];
        let isGrouped = Array(stories.length).fill(false);
        let proxThreshold = 2500 / this.state.width;
        for(let i = 0; i < stories.length; i++){
            if(!isGrouped[i]){
                groupedStories.push({primary: stories[i], others: []});
            }
            else{
                continue;
            }
            for(let j = i + 1; j < stories.length; j++){
                let sepX = Math.abs(stories[i].xCoordinate - stories[j].xCoordinate);
                let sepY = Math.abs(stories[i].yCoordinate - stories[j].yCoordinate);
                let proximate = sepX <= proxThreshold && sepY <= proxThreshold;
                if(proximate && !isGrouped[j]){
                    groupedStories[groupedStories.length - 1].others.push(stories[j]);
                    isGrouped[j] = true;
                }
            }
        }

        return (
            <div className={classes.sections}>
                <div className={classes.container}>
                    <img className={classes.map} src={bg} alt={'map'}/>
                    {groupedStories.map((group) => {
                        if(group.others.length === 0){
                            return(
                                <div style={generateStyle(group.primary)} key={group.primary.title}>
                                    <MapIcon info={group.primary}/>
                                </div>
                            );
                        }
                        else{
                            return(
                                <div style={generateStyle(group.primary)} key={group.primary.title}>
                                    <MapIconMerged info={[group.primary, ...group.others]}/>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(MapSection);