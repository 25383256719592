import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Header from '../../components/Header/Header.js';
import HeaderLinks from '../../components/Header/HeaderLinks.js';
import PageTitle from './Sections/PageTitle.js';
import PageTextContent from './Sections/PageTextContent.js';
import Button from '@material-ui/core/Button';
import {getInfoPage} from '../../services/infoPageService';
import {Redirect} from 'react-router-dom';



const styles = theme => ({
    container: {
        textAlign: 'left',
        backgroundColor: '#68246D',
        height: '99vh'
    },
    card: {
        background: 'white',
        margin: '5px',
        height: '95vh',
        overflowY: 'scroll'
    },
    button: {
        textAlign: 'center',
        padding: '10px'
    }
});

class DonationPage extends React.Component {
    state = {
        title: '',
        text: '',
        link: '',
        redirect: false
    };

    async componentDidMount() {
        const {data: donationInfo} = await getInfoPage('donation');
        const {text, link, title} = donationInfo;
        this.setState({
            text, link, title
        })

    }

    render() {
        const {redirect} = this.state;
        if (redirect) {
            return <Redirect push to="/stories"/>
        }
        const {classes} = this.props;
        const {title, text, link} = this.state;
        return (
            <div>
                {/* Header will be essentially the top navbar where the buttons including the donation and volunteer, will go */}
                <Header rightLinks={<HeaderLinks/>} fixed color="white"/>
                {/* A box container for having all the sections inside */}
                <div className={classes.container}>
                    <div className={classes.card}>
                        <PageTitle text={title}/>
                        <PageTextContent text={text}/>
                        <div className={classes.button}>
                            <a href={link}>
                                <Button style={{background: 'rgb(218, 205, 162)'}} variant="contained" color="default">
                                    Donate Now
                                </Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(DonationPage);
