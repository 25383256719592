import React from 'react';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// core components
import styles from '../../assets/jss/material-kit-react/components/headerStyle.js';
import {Link} from 'react-router-dom';
import ColouredButton from '../ColouredButton/ColouredButton';

import dreamLogo from '../../assets/img/DREAMLogoType.png';

const useStyles = makeStyles(styles);

export default function Header(props) {
    const classes = useStyles();
    const {fixed, mainPage} = props;
    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes.fixed]: fixed
    });


    const brandComponent =
        <Link to="/">
            <img style={{maxWidth: '90px', height: 'auto', width: 'auto'}} src={dreamLogo} alt={'DREAM'}/>
        </Link>;
    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={classes.container}>
                <div className={classes.flex}>{brandComponent}</div>
                {mainPage && <Link to="/donate">
                    <ColouredButton colour='#DACDA2' buttonText='Donate'/>
                </Link>}
                {mainPage && <Link to="/volunteer">
                    <ColouredButton colour='#A5C8D0' buttonText='Volunteer'/>
                </Link>}
                {!mainPage && <Link to="/">
                    <ColouredButton colour='#A5C8D0' buttonText='Back'/>
                </Link>}
            </Toolbar>
        </AppBar>
    );
}
