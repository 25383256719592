import React from 'react';
import {withStyles} from '@material-ui/core/styles';

// Css styling
const styles = theme => ({
    button: {
        backgroundColor: (props) => props.colour,
        height: '30px',
        width: '70px',
        margin: '5px',
        borderStyle: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    }
});

class ColouredButton extends React.Component {
    render() {
        const {classes, buttonText} = this.props;
        return (
            <div>
                <button className={classes.button}>{buttonText}</button>
            </div>
        );
    }
}

export default withStyles(styles)(ColouredButton);